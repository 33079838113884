export type DocumentStatuses =
  | 'new'
  | 'missing'
  | 'reviewing'
  | 'approved'
  | 'incomplete'
  | 'rejected'
  | 'none'
  | 'unknown'

export type AccountStatuses = 'complete' | 'incomplete' | 'pending' | 'unknown'

const getColorFromStatus = (status: DocumentStatuses | AccountStatuses, canTrade = false) => {
  switch (status) {
    case 'new':
      return 'status-new'
    case 'reviewing':
      return 'status-reviewing'
    case 'approved':
      return 'status-approved'
    case 'incomplete':
      return canTrade ? 'status-incomplete-can-trade' : 'status-incomplete'
    case 'rejected':
      return 'status-rejected'
    case 'none':
      return 'status-none'
    case 'missing':
      return 'status-missing'
    case 'unknown':
    default:
      return 'status-unknown'
  }
}
const getIconFromStatus = (status: DocumentStatuses | AccountStatuses, canTrade = false) => {
  switch (status) {
    case 'new':
      return 'mdi-file-star'
    case 'reviewing':
      return 'mdi-magnify-scan'
    case 'approved':
      return 'mdi-check-decagram'
    case 'incomplete':
      return canTrade ? 'mdi-magnify-scan' : 'mdi-progress-alert'
    case 'rejected':
      return 'mdi-file-cancel'
    case 'none':
    case 'unknown':
    default:
      return 'mdi-help-circle-outline'
  }
}

export default () => {
  const ret = {
    getColorFromStatus,
    getIconFromStatus,
  }
  Object.freeze(ret)
  return ret
}
